<template>
  <div class="login-container">
    <img class="top-img" src="@/assets/login/headSplit_02.png" alt />
    <img class="login_tbj" src="src/assets/login/headSplit_02.png" alt />
    <div class="bounced_bg">
      <div class="bounced">
        <div class="border_">
          <span class="login_title">请登录账号</span>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            label-position="left"
          >
            <div class="title-container">
              <!-- <img src="../../assets/login/logo.png" alt> -->
            </div>
            <div class="mask">
              <!-- <img src="@/assets/login/flag.png" alt class="flag"> -->
              <el-form-item prop="username">
                <span class="svg-container">
                  <img src="../../assets/login/user.svg" alt />
                </span>
                <el-input
                  ref="username"
                  v-model.trim="loginForm.username"
                  placeholder="用户名"
                  size="small"
                  name="username"
                  type="text"
                  tabindex="1"
                />
              </el-form-item>
              <el-form-item prop="password">
                <span class="svg-container">
                  <img src="../../assets/login/lock.svg" alt />
                </span>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model.trim="loginForm.password"
                  type="text"
                  auto-complete="off"
                  value=""
                  :class="showEye ? '' : 'passwedText'"
                  placeholder="密码"
                  size="small"
                  name="password"
                  tabindex="2"
                  @blur="capsTooltip = false"
                  @keyup.enter.native="handleLogin"
                />
                <i @click="showEye = !showEye" class="el-icon-view visib" :style="{color:(showEye?'#fff':'')}"></i>
                  <!-- style="text-security:disc; -webkit-text-security:disc;" -->
                <!-- <span class="show-pwd" @click="showPwd">
                  <svg-icon
                    :icon-class="
                      passwordType === 'password' ? 'eye' : 'eye-open'
                    "
                  />
                </span> -->
              </el-form-item>
              <el-form-item prop="verificationCode">
                <div class="verificationContent">
                  <span class="svg-container">
                    <img src="../../assets/login/safety certificate.svg" alt />
                  </span>
                  <el-input
                    ref="verificationCode"
                    v-model.trim="loginForm.verificationCode"
                    placeholder=" 验证码"
                    size="small"
                    name="verificationCode"
                    type="text"
                    tabindex="3"
                    maxlength="4"
                    oninput="value=value.replace(/[\W]/g,'')"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    onblur="this.setAttribute('readonly',true);"
                    @keyup.enter.native="handleLogin"
                  />
                  <img
                    :src="loginForm.verificationUrl"
                    alt
                    class="verificationCode_img"
                    @click="getImageCode"
                  />
                </div>
              </el-form-item>
              <!-- <el-checkbox class="el_checkbox" v-model="checked">
                《<span class="el_checkbox_sapn">用户服务协议</span>》及《<span class="el_checkbox_sapn">用户个人信息保护政策</span>》
              </el-checkbox> -->
              <el-button
                :loading="loading"
                type="primary"
                style="width:100%;background-color:#047BEF;"
                class="loginButton"
                @click.native="handleLogin"
                >登录</el-button
              >
              <!-- <el-button class="loginButton2" type="text">重置密码</el-button> -->
            </div>
          </el-form>
          <div class="border_2"></div>
        </div>
      </div>
    </div>
    <div class="lg-foot ft-12 ft-oppo">
      <span>湖北农业发展集团有限公司</span>
      <span><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="color: #00D2C9">鄂公安网备:42011102004083号</a></span>
    </div>
    <img class="login_dbj" src="src/assets/login/bgimg.png" alt />
  </div>
</template>

<script>
import { getRSApassword } from "@/utils/data-transform";
import { getPublicKey, getImageCode } from "@/api/login";
import Bus from "@/utils/bus";

export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      // if (value.length < 10) {
      //   callback(new Error("密码不能小于10位"));
      // } else {
        callback();
      // }
    };
    return {
      showEye:false,
      publicKey: "",
      loginForm: {
        username: "",
        password: "",
        verificationCode: "",
        verificationUrl: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        verificationCode: [
          { required: true, trigger: "blur", message: "请输入验证码" },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      checked: false,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
    this.getPublicKey();
    this.getImageCode();
    Bus.$on("getImageCode", this.getImageCode);
  },
  created() {
    this.getIp("http://pv.sohu.com/cityjson?ie=utf-8").then((res) => {
      console.log(res);
      this.$store.dispatch("global/changeGlobal", {
        key: "ipParams",
        value: res,
      });
    });
  },
  methods: {
    // 获取ip
    getIp(url) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
        let timer = setInterval(() => {
          resolve(window.returnCitySN);
          if (window.returnCitySN) {
            document.getElementsByTagName("head")[0].removeChild(script);
            clearInterval(timer);
          }
        }, 500);
      });
    },
    /**
     * 获取验证码
     */
    getImageCode() {
      getImageCode().then((res) => {
        this.loginForm.verificationCode = "";
        this.loginForm.verificationUrl = res;
      });
    },
    getPublicKey() {
      getPublicKey({}).then((res) => {
        this.publicKey = res.data;
      });
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // const tempForm = new FormData();
          // tempForm.append("grant_type", "password");
          // tempForm.append("username", this.loginForm.username);
          // tempForm.append(
          //   "password",
          //   getRSApassword(this.publicKey, this.loginForm.password)
          // );
          // tempForm.append("verificationCode", this.loginForm.verificationCode);
          const params = {
            account: this.loginForm.username,
            password: getRSApassword(this.publicKey, this.loginForm.password),
            verifyCode: this.loginForm.verificationCode,
          };
          this.$store
            .dispatch("user/login", params)
            .then((res) => {
              localStorage.setItem("username", this.loginForm.username);
              localStorage.setItem("usernameTwo", res.data.username);
              // console.log(this.redirect);
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              if (
                error.response &&
                error.response.data.messages === "验证码已过期，请重新获取！"
              ) {
                this.getImageCode();
                this.loginForm.verificationCode = "";
              }
              if (
                error.response &&
                error.response.data.messages === "验证码错误！"
              ) {
                this.loginForm.verificationCode = "";
              }
              if (
                error.response &&
                error.response.data.messages === "用户名或密码错误！"
              ) {
                this.loginForm.password = "";
                this.loginForm.verificationCode = "";
                this.getImageCode();
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #255aac;
$light_gray: #fff;
$cursor: #fff;

.passwedText {
  -webkit-text-security: disc;
}

.verificationCode_img {
  height: 38px;
  margin-right: 1px;
}

.el_checkbox {
  margin: 0 0 10px 0;
  color: white;
}

.el_checkbox_sapn:hover {
  cursor: pointer;
  color: #17f1ec;
  text-decoration: underline;
}

::v-deep .el-input__inner {
  color: #fff;
  border: none;
  line-height: 31px;
  margin-right: 3px;
  padding-right: 20px;
}

.el-form-item:focus-within {
  border: 1px solid #17f1ec;
  box-shadow: 0px 0px 15px 2px #17f1ec;
}

.border_::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-left: 2px solid #17f1ec;
  border-top: 2px solid #17f1ec;
}

.border_::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-right: 2px solid #17f1ec;
  border-top: 2px solid #17f1ec;
}

.border_2::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-left: 2px solid #17f1ec;
  border-bottom: 2px solid #17f1ec;
}

.border_2::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-right: 2px solid #17f1ec;
  border-bottom: 2px solid #17f1ec;
}

.login_title {
  font-size: 1.15vw;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  line-height: 33px;
  position: relative;
}

.bounced_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bounced {
  position: relative;
  background: hska(0, 0%, 100%, 0.3);
  background: rgb(0, 104, 197);
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  padding: 40px 30px;
  background: linear-gradient(
    180deg,
    rgba(0, 76, 255, 0.02),
    rgba(0, 95, 255, 0.3) 100%
  );
  backdrop-filter: blur(10px);
}

// .bounced::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   filter: 20px;
//   background: url("~@/assets/login/indexbg.jpg") 0 / cover fixed;
//   filter: blur(6px);
// }

.login_dbj {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.login_tbj {
  width: 100%;
}

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .top-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .verificationContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .el-input {
    display: flex;
    align-items: center;
    height: 40px;
    /* width: 90.3%; */

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 40px;
      caret-color: #17f1ec;
      outline: none;

      &:-webkit-autofill,
      textarea:-webkit-autofill,
      select:-webkit-autofill {
        box-shadow: 0 0 0 1000px #4c75b4 inset !important;
        -webkit-text-fill-color: $cursor !important;
        position: relative;
        top: -1px;
        height: 37px;
      }
    }
  }

  .el-form-item {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    border: 1px solid #87d2ff;
    background: rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    color: #454545;
  }
}

$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

::v-deep .el-input input:last-child[placeholder="验证码"] {
  position: relative;
  top: 4px;
}

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  background-size: 100% 100%;
  background-image: url("~@/assets/login/indexbg.jpg");
  overflow: hidden;

  .login-form {
    position: relative;
    z-index: 1;
  }

  .svg-container {
    padding: 0 0 0 10px;
    color: #889aa4;
    vertical-align: middle;
    width: 40px;
    margin-right: 10px;
  }

  .title-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    img {
      // width: 350px;
      width: 70%;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.mask {
  width: 380px;
  margin: 0 auto;
}

.loginButton {
  position: relative;
  width: 320px;
  height: 40px;
  background: #00d2c9 !important;
  border-radius: 0px;
  font-size: 0.95vw;
  padding: 0px 20px;
  border: none;
}

.loginButton2 {
  position: relative;
  color: #17fff4;
  width: 100%;
  left: -10px;
  margin: 15px 0 0 0;
}

::v-deep .el-form-item__content {
  // line-height: 0px;
  // position: relative;
  // font-size: 14px;
  // border: 1px solid #87d2ff;
  // height: 40px;
  // box-sizing: border-box;
  line-height: 0px;
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
}

::v-deep .el-icon-view:before {
  position: relative;
  top: 6%;
}

.el-form-item__content:focus-within {
  border: 1px solid #17f1ec;
  box-shadow: 0px 0px 4px 2px rgba(23, 241, 236, 1);
}
.flag {
  position: absolute;
  top: 0;
  left: 0;
}
::v-deep .el-form-item__content{
  position: relative;
  .visib{
    position: absolute;
    right: 8px;
    top: 14px;
    color: #999;
    &:hover{
      color: #fff;
      cursor: pointer;
    }
  }

}

.lg-foot {
  width: 100%;
  position: fixed;
  bottom: 1vw;
  display: flex;
  justify-content: center;

  span {
    margin-right: 5px;
  }
}
</style>
